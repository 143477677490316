import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const CtaBarStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-cta-bar {
        .leshen-form {
          width: max-content;

          * {
            font-size: 16px;
          }

          label {
            padding-bottom: 0;
          }

          .submit-button {
            padding: 8px 16px;
          }

          .error-text {
            left: 14px;
            font-size: 10px;
          }
        }
      }
    `}
  />
))

export default CtaBarStyles
