import React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const GlobalStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      body {
        color: ${theme.colors.dark.base.hex};
      }
    `}
  />
))

export default GlobalStyles
