module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.getquantumfiber.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-MTCZHQ5","brandTokens":["QF","DTV"],"mapiBrandToken":"EMB","siteName":"getquantumfiber","alternateName":"getquantumfiber.com","siteURL":"https://www.getquantumfiber.com","defaultTitleTemplate":"","defaultPhone":"8555560821","phoneSymbol":"-","defaultPromoCode":"159760","removeTrailingSlashes":true,"smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10045795","vwoProjectID":"894940","cobra":{"sitePath":"getquantumfiber.com","buttonLink":"/cart","buttonText":"Order Online","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"getquantumfiber","short_name":"getquantumfiber","start_url":"/","background_color":"#503296","theme_color":"#503296","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f79a1e257c6142fbf7eb0e7783b5abd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
