const merge = require('lodash.merge')

// Import the brand level theme so it can be merged.
const brandTheme = require('gatsby-theme-centurylink/theme')

// Merge the brand level theme and the site level theme.
// The second object being passed to the merge function
// is the site level theme. Anything placed here will
// override the brand level theme.
module.exports = merge(brandTheme, {
  colors: {
    primary: '#503296',
    secondary: '#7b8EE1',
    tertiary: '#2D005A',
    dark: '#3C414B',
    light: '#EEF3F5',
    warning: '#F4CA12',
    error: '#F83F00',
    success: '#8BC525',
    background1: '#F0FBF4',
    background2: '#D0F3DF',
    background3: '#E5EDFA',
    background4: '#CCDBF5',
    background5: '#CBCBCB',
    background6: '#575F68',
  },
  backgroundColors: {
    light: {
      textColor: '#3C414B',
      // boxShadow: '0 4px 8px #191961',
    },
  },
  components: {
    Button: {
      rounded: 'min',
      variants: {
        primary: {
          color: 'white',
          hoverColor: 'white',
          activeColor: 'white',
        },
        light: {
          textColor: 'black',
        },
      },
    },
  },
})
